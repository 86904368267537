.checkmark__circle {
  stroke-dasharray: 432; /* ORIGINALLY 166px 216*/
  stroke-dashoffset: 432; /* ORIGINALLY 166px */
  stroke-width: 3;
  stroke-miterlimit: 10;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark_green {
  width: 212px; /* ORIGINALLY 56px 106*/
  height: 212px; /* ORIGINALLY 56px */
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fillgreen 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark_red {
  width: 212px; /* ORIGINALLY 56px 106*/
  height: 212px; /* ORIGINALLY 56px */
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: auto;
  box-shadow: inset 0px 0px 0px #d06079;
  animation: fillred 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 196; /* ORIGINALLY 48px 96*/
  stroke-dashoffset: 196; /* ORIGINALLY 48px*/
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fillgreen {
  100% {
    box-shadow: inset 0px 0px 0px 110px #7ac142;
  }
}

@keyframes fillred {
  100% {
    box-shadow: inset 0px 0px 0px 110px #d06079;
  }
}

/*    // Check
          <svg
            class="checkmark_green"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
          >
            <circle
              class="checkmark__circle"
              cx="26"
              cy="26"
              r="25"
              fill="none"
              stroke="#7ac142"
            />
            <path
              class="checkmark__check"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
            />
          </svg>

    // Cross
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 130.2 130.2"
            class="checkmark_red"
          >
            <circle
              class="checkmark__circle"
              fill="none"
              stroke="#D06079"
              cx="65.1"
              cy="65.1"
              r="62.1"
            />
            <line
              class="checkmark__check"
              fill="none"
              stroke-width="10"
              stroke-miterlimit="10"
              x1="34.4"
              y1="37.9"
              x2="95.8"
              y2="92.3"
            />
            <line
              class="checkmark__check"
              fill="none"
              stroke-width="10"
              stroke-miterlimit="10"
              x1="95.8"
              y1="38"
              x2="34.4"
              y2="92.2"
            />
          </svg>

          */
